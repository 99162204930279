
import { Component, Vue } from 'vue-property-decorator'
import {
  apiWorkbenchIndex,
  apiWorkbenchTopAmount,
  apiWorkbenchTopUser
} from '@/api/home'

@Component
export default class Home extends Vue {
  indexData: object = {
      platform_info: {},
      accumulated_data: {}
  };

  topAmount: Array<Object> = [];
  topAmountCount = 0;

  topUser: Array<Object> = [];
  topUserCount = 0;

  // 获取工作台数据
  async getWorkbenchIndexData(): Promise<void> {
      const res: object = await apiWorkbenchIndex()
      this.indexData = res
  }

  // 获取工作台营业额排名
  async getWorkbenchTopAmountData(page: number): Promise<void> {
      const res: object = await apiWorkbenchTopAmount({
          page_no: page,
          page_size: 10
      })
      this.topAmount = (res as any).lists
      this.topAmountCount = (res as any).count
  }

  // 获取工作台商城排名
  async getWorkbenchTopUserData(page: number): Promise<void> {
      const res: object = await apiWorkbenchTopUser({
          page_no: page,
          page_size: 10
      })
      this.topUser = (res as any).lists
      this.topUserCount = (res as any).count
  }

  goPage(url: string): void {
      this.$router.push(url)
  }

  created(): void {
      this.getWorkbenchIndexData()
      this.getWorkbenchTopAmountData(1)
      this.getWorkbenchTopUserData(1)
  }
}
